const About = () => {
  document.body.style.backgroundColor = "#a11961";
  return (
    <section className="text-section">
      <div className="text-section__header">
        <img
          src="/images/tape/tape-top.svg"
          alt="Tape"
          className="tape__mobile"
        />
        <img
          src="/images/tape/tape-top-left.svg"
          alt="Tape"
          className="tape__top-left"
        />
        <img
          src="/images/tape/tape-top-right.svg"
          alt="Tape"
          className="tape__top-right"
        />
      </div>
      <div className="text-section__content">
        <h2>CKE zoekt Koning voor 1 dag</h2>
        <p><strong>Samen met jullie gaan wij op zoek naar bijzonder Eindhovenaren. Mensen die de stad fijn, leefbaar en
          bijzonder maken. Eentje van hen wordt op Koningsdag 2021 de Koning voor 1 dag. </strong></p>

        <h3>Hoe werkt het?</h3>
        <p>
          Iedereen in Eindhoven mag tot 3 april iemand nomineren. Het nomineren gaat online: via Facebook en Instagram
          (@koningvooreendag). Maar in een groot aantal wijken gaan artiesten ook live de straat op om nominaties op te
          halen.
        </p>
        <h3>Online strijd</h3>
        <p>
          Als de koningen van de buurt zijn gekozen gaan ze in verschillende online rondes de strijd met elkaar aan. Ze
          spelen verschillende spannende quizrondes over Eindhoven en het Koningshuis. De wijk Battle en Finale zijn
          live te volgen op deze website.
        </p>
        <p>
          <img src="/images/route-kv1d.jpg" alt="Online Strijd"/>
        </p>
        <h3>Iedereen een beetje koning</h3>
        <p>
          Uiteraard trekken we op Koningsdag ook live de wijken in met een aantal mooie tronen, lakeien en fotografen.
          Mensen worden koninklijk op de foto gezet en een krijgen een bijzondere versie van het Wilhelmus te horen of
          te zien, uitgevoerd door Eindhovense verenigingen en artiesten. De tronen worden de komende weken gemaakt door
          Eindhovense scholen.
        </p>
      </div>
      <div className="text-section__footer">
        <img
          src="/images/tape/tape-bottom.svg"
          alt="Tape"
          className="tape__mobile"
        />
        <img
          src="/images/tape/tape-bottom-left.svg"
          alt="Tape"
          className="tape__bottom-left"
        />
        <img
          src="/images/tape/tape-bottom-right.svg"
          alt="Tape"
          className="tape__bottom-right"
        />
      </div>
    </section>
  );
};

export default About;
