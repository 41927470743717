const Colofon = () => {
  document.body.style.backgroundColor = "#e23b17";
  return (
    <section className="text-section">
      <div className="text-section__header">
        <img src="/images/tape/tape-top.svg" alt="Tape"/>
      </div>
      <div className="text-section__content text--large">
        <p>
          Koning voor 1 dag is een initiatief van <a href="https://cke.nl" target="_blank"
          rel="noreferrer">CKE</a>
        </p>
        <p>
          <span style={{fontSize: '1rem', color: 'rgb(247 175 72)'}}>In samenwerking met:</span><br/>
          Effenaar, Parktheater, Buurtfonds Woonbedrijf en gemeente Eindhoven.</p>

        <p>
          <span style={{fontSize: '1rem', color: 'rgb(247 175 72)'}}>Koning voor 1 dag in de wijken is mogelijk gemaakt door:</span><br/>
          Inbo Architecten (ontwerp)<br/>
          Van der Meijs: renovatie en onderhoud<br/>
          A van Liempd: sloopbedrijven<br/><br/>
          Amateurkunstverenigingen in Eindhoven<br/>
          Bewonersorganisaties in Eindhoven
        </p>

        <p>
          <span style={{fontSize: '1rem', color: 'rgb(247 175 72)'}}>Ontwerp Huisstijl KV1D:</span><br/>
          Bianca van Rooij en Lian Kroes
        </p>

        <p>
          <span style={{fontSize: '1rem', color: 'rgb(247 175 72)'}}>Fotografie:</span><br/>
          Roberto Bogers
        </p>

        <p>
          <span style={{fontSize: '1rem', color: 'rgb(247 175 72)'}}>Met dank aan:</span><br/>
          Wij-Eindhoven, KnaapenGroep, CultuurStation en het Summa College.
        </p>

        <p>
          Vragen? Mail naar <a href="mailto:marketing@cke.nl">marketing@cke.nl</a>
        </p>
      </div>
      <div className="text-section__footer">
        <img src="/images/tape/tape-bottom.svg" alt="Tape"/>
      </div>
    </section>
  );
};

export default Colofon;
