import { Link, useLocation } from "react-router-dom";
import { siteRoot } from "../config";

const Header = () => {
  const { pathname } = useLocation();

  const active = "page-header__link--active";
  const nonActive = "";

  return (
    <div className="page-header flex justify-end">
      <nav className="page-header__navigation">
        <Link
          className={`page-header__link page-header__link--home  ${
            pathname === siteRoot ? active : nonActive
          }`}
          to={siteRoot}
        >
          <img src="/images/icon-home.svg" alt="Home" className="icon" />
          &nbsp; <span>Home</span>
        </Link>
        <Link
          className={`page-header__link ${
            pathname === `${siteRoot}/wat-is-koning-voor-een-dag`
              ? active
              : nonActive
          }`}
          to={`${siteRoot}/wat-is-koning-voor-een-dag`}
        >
          Wat is Koning voor 1 dag
        </Link>

        <Link
          className={`page-header__link ${
            pathname === `${siteRoot}/koningen-van-de-buurt` ? active : nonActive
          }`}
          to={`${siteRoot}/koningen-van-de-buurt`}
        >
          Koningen van de buurt
        </Link>

        <Link
          className={`page-header__link ${
            pathname === `${siteRoot}/even-koning` ? active : nonActive
          }`}
          to={`${siteRoot}/even-koning`}
        >
          Even koning
        </Link>

        {/*<Link*/}
        {/*  className={`page-header__link ${*/}
        {/*    pathname === `${siteRoot}/social-wall` ? active : nonActive*/}
        {/*  }`}*/}
        {/*  to={`${siteRoot}/social-wall`}*/}
        {/*>*/}
        {/*  Social Wall*/}
        {/*</Link>*/}

        <Link
          className={`page-header__link ${
            pathname === `${siteRoot}/colofon` ? active : nonActive
          }`}
          to={`${siteRoot}/colofon`}
        >
          Colofon
        </Link>
      </nav>
      <div className="page-header__logo">
        <Link
          className={` ${pathname === siteRoot ? active : nonActive}`}
          to={siteRoot}
        >
          <img src="/images/logo.png" alt="Koning voor een dag" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
