const Placeholder = () => {
  return (
    <img
      className="object-scale-down w-screen h-screen"
      src="/placeholder.png"
      alt="Koning voor een dag"
    />
  );
};

export default Placeholder;
