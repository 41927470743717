const Koningen = () => {

  document.body.style.backgroundColor = "#1caa82";
  return (
    <div>
      <section className="text-section">
        <div className="text-section__header">
          <img src="/images/tape/tape-top.svg" alt="Tape"/>
        </div>
        <div className="text-section__content text--large">
          <p>Dit zijn de Eindhovens Koningen van de Buurten die de strijd met elkaar aangaan om de titel Koning voor 1
            dag. Al draait het natuurlijk niet om winnen. Al deze bijzondere mensen verdienen een podium.</p>
          <div className="video-embed">
            <div className="video-embed__content">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/zK_c3VnKEvw"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            </div>
          </div>
        </div>
        <div className="text-section__footer">
          <img src="/images/tape/tape-bottom.svg" alt="Tape"/>
        </div>
      </section>
    </div>
  );
};

export default Koningen;
