import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import CookieConsent from "react-cookie-consent";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Placeholder from "./pages/Placeholder";
import SocialWall from "./pages/SocialWall";
import About from "./pages/About";
import Koningen from "./pages/Koningen";
import Colofon from "./pages/Colofon";
import EvenKoning from "./pages/EvenKoning";
import Video from "./pages/Video";
import { siteRoot } from "./config";

const App = () => {
  return (
    <main>
      {/* <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="cookie-consent-kv1d"
                style={{fontSize: ".8em", background: "#2B373B"}}
                buttonStyle={{color: "#4e503b", fontSize: ".8em", fontWeight: '700'}}
                expires={150}
            >
              Koning voor 1 dag gebruikt functionele en analytische cookies voor website optimalisatie en statistieken. <a href="#!">Meer info</a>
            </CookieConsent> */}
      <Router>
        <Switch>
          <Route path={`${siteRoot}/wat-is-koning-voor-een-dag`}>
            <Header />
            <About />
            <Footer />
          </Route>

          <Route path={`${siteRoot}/even-koning`}>
            <Header />
            <EvenKoning />
            <Footer />
          </Route>

          <Route path={`${siteRoot}/koningen-van-de-buurt`}>
            <Header />
            <Koningen />
            <Footer />
          </Route>

          <Route path={`${siteRoot}/colofon`}>
            <Header />
            <Colofon />
            <Footer />
          </Route>

          <Route path={`${siteRoot}/social-wall`}>
            <Header />
            <SocialWall />
            <Footer />
          </Route>

          <Route path={`${siteRoot}/video`}>
            <Header />
            <Video />
            <Footer />
          </Route>

          <Route path={siteRoot}>
            <Header />
            <SocialWall />
            <Footer />
          </Route>

          <Route path="/">
            <Placeholder />
          </Route>
        </Switch>
      </Router>
    </main>
  );
};

export default App;
