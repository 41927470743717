const Footer = () => {
  return (
    <footer className="page-footer">
      <a
        className="page-footer__link"
        href="https://cke.nl"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/logo-cke.png" alt="Koningsdag Eindhoven 2021" />
      </a>
      <a
        className="page-footer__link"
        href="https://koningsdageindhoven.nl"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/logo-kd21.svg" alt="Koningsdag Eindhoven 2021" />
      </a>
    </footer>
  );
};

export default Footer;
