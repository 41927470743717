import { useEffect, useRef } from "react";

const JWPlayerLive = ({ channelId }) => {
  const playerElement = useRef(null);

  useEffect(() => {
    if (!playerElement?.current) return;

    if(channelId) {
      // eslint-disable-next-line
      jwLiveChannelSetup({
        embedContainer: playerElement.current,
        channelId
      })
    }

    // return () => player.remove();
  }, [channelId]);

  return <div className="jw-player" ref={playerElement}></div>;
};

export default JWPlayerLive;
