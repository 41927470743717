import Helmet from "react-helmet";

const SocialsTimeline = ({ wallId }) => {
  return (
    <div className="taggbox-container social-section">
      <div
        className="taggbox-socialwall"
        data-wall-id={wallId}
        view-url={`https://widget.taggbox.com/${wallId}`}
      ></div>

      <Helmet>
        <script
          src="https://widget.taggbox.com/embed.min.js"
          type="text/javascript"
        ></script>
      </Helmet>
    </div>
  );
};

export default SocialsTimeline;
