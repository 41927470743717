const About = () => {
  document.body.style.backgroundColor = "#3D82C4";
  return (
    <section className="text-section">
      <div className="text-section__header">
        <img
          src="/images/tape/tape-top.svg"
          alt="Tape"
          className="tape__mobile"
        />
        <img
          src="/images/tape/tape-top-left.svg"
          alt="Tape"
          className="tape__top-left"
        />
        <img
          src="/images/tape/tape-top-right.svg"
          alt="Tape"
          className="tape__top-right"
        />
      </div>
      <div className="text-section__content text--large">
        <p>&nbsp;</p>
        <p>
          Op Koningsdag mag iedereen in Eindhoven zich even koning voelen. Stonden we bij jou voor de deur?
        </p>

        <p>
          Dan staat jouw foto <a href="https://www.flickr.com/photos/ckeeindhoven/albums" target="_blank"
                                 rel="noreferrer">hier</a>
        </p>
        <p>&nbsp;</p>
        <div className="text-section__footer">
          <img src="/images/tape/tape-bottom.svg" alt="Tape"/>
        </div>
      </div>
    </section>
  );
};

export default About;
