import JWPlayerLive from "../components/JWPlayerLive";

const Video = () => {
  document.body.style.backgroundColor = "#3D82C4";
  return (
    <div>
      <section className="text-section">
        <div className="text-section__content text--large">
          <div className="video-embed">
            <div className="video-embed__content">
              <JWPlayerLive channelId="O4FKnNcI"></JWPlayerLive>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Video;
